import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Link } from 'gatsby';

// markup
const PrivacyAndCookiePolicy = () => (
  <Layout>
    <SEO
      title="Privacy and Cookies"
      description="Maistro privacy and cookie policy"
    />
    <div className="container">
      <article className="post text-base">
        <div>
          <h5>Privacy policy</h5>
          <p>
            This privacy policy applies between you, the User of this Website
            and Maistro UK Ltd, the owner and provider of this Website. Maistro
            UK Ltd takes the privacy of your information very seriously. This
            privacy policy applies to our use of any and all Data collected by
            us or provided by you in relation to your use of the Website.
          </p>
          <p>
            This privacy policy should be read alongside, and in addition to,
            our Terms and Conditions, which can be found at:{' '}
            <a href="/terms-of-use/">https://www.maistro.com/terms-of-use/</a>.
          </p>

          <p>
            <strong>Please read this privacy policy carefully</strong>.
          </p>
          <h5>Definitions and interpretation</h5>
          <ol>
            <li>In this privacy policy, the following definitions are used:</li>
          </ol>
          <table>
            <tbody>
              <tr>
                <td>
                  <h5>Data</h5>
                </td>
                <td>
                  <p>
                    collectively all information that you submit to Maistro UK
                    Ltd via the Website. This definition incorporates, where
                    applicable, the definitions provided in the Data Protection
                    Laws;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Cookies</h5>
                </td>
                <td>
                  <p>
                    a small text file placed on your computer by this Website
                    when you visit certain parts of the Website and/or when you
                    use certain features of the Website. Details of the cookies
                    used by this Website are set out in the clause below (
                    <strong>Cookies</strong>
                    );
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Data Protection Laws</h5>
                </td>
                <td>
                  <p>
                    any applicable law relating to the processing of personal
                    Data, including but not limited to the Directive 96/46/EC
                    (Data Protection Directive) or the GDPR, and any national
                    implementing laws, regulations and secondary legislation,
                    for as long as the GDPR is effective in the UK;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>GDPR</h5>
                </td>
                <td>
                  <p>the General Data Protection Regulation (EU) 2016/679;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Maistro UK Ltd, we or us</h5>
                </td>
                <td>
                  <p>
                    Maistro UK Ltd, a company incorporated in England and Wales
                    with registered number 06211244 whose registered office is
                    at Rowan House North, 1 The Professional Quarter, Shrewsbury
                    Business Park, Shrewsbury, United Kingdom, SY2 6LG;{' '}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>UK and EU Cookie Law</h5>
                </td>
                <td>
                  <p>
                    the Privacy and Electronic Communications (EC Directive)
                    Regulations 2003 as amended by the Privacy and Electronic
                    Communications (EC Directive) (Amendment) Regulations 2011;
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>User or you</h5>
                </td>
                <td>
                  <p>
                    any third party that accesses the Website and is not either
                    (i) employed by Maistro UK Ltd and acting in the course of
                    their employment or (ii) engaged as a consultant or
                    otherwise providing services to Maistro UK Ltd and accessing
                    the Website in connection with the provision of such
                    services; and
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Website</h5>
                </td>
                <td>
                  <p>
                    the website that you are currently using, www.maistro.com,
                    and any sub-domains of this site unless expressly excluded
                    by their own terms and conditions.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <ol start="2">
            <li>
              In this privacy policy, unless the context requires a different
              interpretation:
              <ol>
                <li>the singular includes the plural and vice versa;</li>
                <li>
                  references to sub-clauses, clauses, schedules or appendices
                  are to sub-clauses, clauses, schedules or appendices of this
                  privacy policy;
                </li>
                <li>
                  a reference to a person includes firms, companies, government
                  entities, trusts and partnerships;
                </li>
                <li>
                  “including” is understood to mean “including without
                  limitation”;
                </li>
                <li>
                  reference to any statutory provision includes any modification
                  or amendment of it;
                </li>
                <li>
                  the headings and sub-headings do not form part of this privacy
                  policy.
                </li>
              </ol>
            </li>
          </ol>
          <h5>Scope of this privacy policy</h5>
          <ol start="3">
            <li>
              This privacy policy applies only to the actions of Maistro UK
              Ltd and Users with respect to this Website. It does not extend to
              any websites that can be accessed from this Website including, but
              not limited to, any links we may provide to social media websites.
            </li>
            <li>
              For purposes of the applicable Data Protection Laws, Maistro UK
              Ltd is the “data controller”. This means that Maistro UK Ltd
              determines the purposes for which, and the manner in which, your
              Data is processed.
            </li>
          </ol>
          <h5>Data collected</h5>
          <ol start="5">
            <li>
              We may collect the following Data, which includes personal Data,
              from you:
              <ol>
                <li>name;</li>
                <li>date of birth;</li>
                <li>job title;</li>
                <li>profession;</li>
                <li>
                  contact Information such as email addresses and telephone
                  numbers;
                </li>
                <li>
                  financial information such as credit / debit card numbers;
                </li>
                <li>IP address (automatically collected);</li>
                <li>educational history;</li>
                <li>
                  work experience and other information from your CV if you
                  provide this to us;
                </li>
                <li>
                  market research data such as readership patterns and usage
                  statistics;
                </li>
              </ol>
            </li>
          </ol>
          <p>in each case, in accordance with this privacy policy.</p>
          <h5>How we collect Data</h5>
          <ol start="6">
            <li>
              We collect Data in the following ways:
              <ol>
                <li>data is given to us by you; and;</li>
                <li>data is collected automatically.</li>
              </ol>
            </li>
          </ol>
          <h5>Data that is given to us by you</h5>
          <ol start="7">
            <li>
              Maistro UK Ltd will collect your Data in a number of ways, for
              example:
              <ol>
                <li>
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li>
                  when you register with us and set up an account to receive our
                  products/services;
                </li>
                <li>
                  when you make payments to us, through this Website or
                  otherwise;
                </li>
                <li>when you use our services;</li>
              </ol>
            </li>
          </ol>
          <p>in each case, in accordance with this privacy policy.</p>
          <h5>Data that is collected automatically</h5>
          <ol start="8">
            <li>
              To the extent that you access the Website, we will collect your
              Data automatically, for example:
              <ol>
                <li>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
                <li>
                  we will collect your Data automatically via cookies, in line
                  with the cookie settings on your browser. For more information
                  about cookies, and how we use them on the Website, see the
                  section below, headed “Cookies”.
                </li>
              </ol>
            </li>
          </ol>
          <h5>Our use of Data</h5>
          <ol start="9">
            <li>
              Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
              <ol>
                <li>internal record keeping;</li>
                <li>improvement of our products / services;</li>
                <li>
                  contact for market research purposes which may be done using
                  email, telephone, fax or mail. Such information may be used to
                  customise or update the Website;
                </li>
              </ol>
            </li>
          </ol>
          <p>in each case, in accordance with this privacy policy.</p>
          <ol start="10">
            <li>
              We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed “Your rights” below).
            </li>
            <li>
              When you register with us and set up an account to receive our
              services, the legal basis for this processing is the performance
              of a contract between you and us and/or taking steps, at your
              request, to enter into such a contract.
            </li>
          </ol>
          <h5>Who we share Data with</h5>
          <ol start="12">
            <li>
              We may share your Data with the following groups of people for the
              following reasons:
              <ol>
                <li>
                  any of our group companies or affiliates – to process
                  registrations and enquiries, provide reports to our customers
                  and to build up marketing profiles;
                </li>
                <li>
                  our employees, agents and/or professional advisors – to
                  provide you with a personalised service, allowing you to
                  participate in interactive features of our service, where you
                  choose to do so;
                </li>
                <li>
                  relevant authorities – where obligated to disclose by reason
                  of any law, regulation or court order;
                </li>
              </ol>
            </li>
          </ol>
          <p>in each case, in accordance with this privacy policy.</p>
          <h5>Keeping Data secure</h5>
          <ol start="13">
            <li>
              We will use technical and organisational measures to safeguard
              your Data, for example:
              <ol>
                <li>
                  access to your account is controlled by a password and a
                  user-name that is unique to you.
                </li>
                <li>we store your Data on secure servers.</li>
                <li>
                  payment details are encrypted using SSL technology (typically
                  you will see a lock icon or green address bar (or both) in
                  your browser when we use this technology).
                </li>
              </ol>
            </li>
            <li>
              We are ISO 27001 and Cyber Essentials Plus certified. This family of standards helps us manage your Data
              and keep it secure.
            </li>
            <li>
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:{' '}
              <Link href="mailto:gdpr-queries@maistro.com">
                gdpr-queries@maistro.com
              </Link>
            </li>
            <li>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit {' '}
              <Link href="https://www.getsafeonline.org">
                www.getsafeonline.org
              </Link>
              . Get Safe Online is supported by HM Government and leading businesses.
            </li>
          </ol>
          <h5>Data retention</h5>
          <ol start="17">
            <li>
              Unless a longer retention period is required or permitted by law,
              we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </li>
            <li>
              Even if we delete your Data, it may persist on backup or archival
              media for legal, tax or regulatory purposes.
            </li>
          </ol>
          <h5>Your rights</h5>
          <ol start="19">
            <li>
              You have the following rights in relation to your Data:
              <ol>
                <li>
                  <strong>Right to access</strong>– the right to request (i)
                  copies of the information we hold about you at any time, or
                  (ii) that we modify, update or delete such information. If we
                  provide you with access to the information we hold about you,
                  we will not charge you for this, unless your request is
                  “manifestly unfounded or excessive.” Where we are legally
                  permitted to do so, we may refuse your request. If we refuse
                  your request, we will tell you the reasons why.
                </li>
                <li>
                  <strong>Right to correct</strong>– the right to have your Data
                  rectified if it is inaccurate or incomplete.
                </li>
                <li>
                  <strong>Right to erase</strong>– the right to request that we
                  delete or remove your Data from our systems.
                </li>
                <li>
                  <strong>Right to restrict our use of your Data</strong>– the
                  right to “block” us from using your Data or limit the way in
                  which we can use it.
                </li>
                <li>
                  <strong>Right to data portability</strong>– the right to
                  request that we move, copy or transfer your Data.
                </li>
                <li>
                  <strong>Right to object</strong>– the right to object to our
                  use of your Data including where we use it for our legitimate
                  interests.
                </li>
              </ol>
            </li>
            <li>
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address: {' '}
              <Link href="mailto:gdpr-queries@maistro.com">
                gdpr-queries@maistro.com
              </Link>
            </li>
            <li>
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information Commissioner’s Office (ICO). The ICO’s
              contact details can be found on their website at{' '}
              <Link href="https://ico.org.uk">
                https://ico.org.uk.
              </Link>
            </li>
            <li>
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
          </ol>
          <h5>Links to other websites</h5>
          <ol start="23">
            <li>
              This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </li>
          </ol>
          <h5>Changes of business ownership and control</h5>
          <ol start="24">
            <li>
              Maistro UK Ltd may, from time to time, expand or reduce our
              business and this may involve the sale and/or the transfer of
              control of all or part of Maistro UK Ltd. Data provided by Users
              will, where it is relevant to any part of our business so
              transferred, be transferred along with that part and the new owner
              or newly controlling party will, under the terms of this privacy
              policy, be permitted to use the Data for the purposes for which it
              was originally supplied to us.
            </li>
            <li>
              We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </li>
            <li>
              In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </li>
          </ol>
          <h5>Cookies</h5>
          <ol start="27">
            <li>
              This Website may place and access certain Cookies on your
              computer. Maistro UK Ltd uses Cookies to improve your experience
              of using the Website and to improve our range of services. Maistro
              UK Ltd has carefully chosen these Cookies and has taken steps to
              ensure that your privacy is protected and respected at all times.
            </li>
            <li>
              All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </li>
            <li>
              Before the Website places Cookies on your computer, you will be
              presented with a message bar requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling Maistro UK Ltd to provide a better experience and service
              to you. You may, if you wish, deny consent to the placing of
              Cookies; however certain features of the Website may not function
              fully or as intended.
            </li>
            <li>This Website may place the following Cookies:</li>
          </ol>
          <table width="100%">
            <tbody>
              <tr>
                <td width="30%">
                  <h5>Type of Cookie</h5>
                </td>
                <td width="70%">
                  <h5>Purpose</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Strictly necessary cookies</p>
                </td>
                <td>
                  <p>
                    These are cookies that are required for the operation of our
                    website. They include, for example, cookies that enable you
                    to log into secure areas of our website or make use of
                    contact and feedback forms.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <ol start="31">
            <li>
              You can find a list of Cookies that we use in the Cookies
              Schedule.
            </li>
            <li>
              You can choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please consult the help
              menu in your internet browser.
            </li>
            <li>
              You can choose to delete Cookies at any time; however you may lose
              any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </li>
            <li>
              It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </li>
            <li>
              For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </li>
          </ol>
          <h5>General</h5>
          <ol start="36">
            <li>
              You may not transfer any of your rights under this privacy policy
              to any other person. We may transfer our rights under this privacy
              policy where we reasonably believe your rights will not be
              affected.
            </li>
            <li>
              If any court or competent authority finds that any provision of
              this privacy policy (or part of any provision) is invalid, illegal
              or unenforceable, that provision or part-provision will, to the
              extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will
              not be affected.
            </li>
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li>
              This Agreement will be governed by and interpreted according to
              the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </li>
          </ol>
          <h5>Changes to this privacy policy</h5>
          <ol start="40">
            <li>
              Maistro UK Ltd reserves the right to change this privacy policy as
              we may deem necessary from time to time or as may be required by
              law. Any changes will be immediately posted on the Website and you
              are deemed to have accepted the terms of the privacy policy on
              your first use of the Website following the alterations.
              <br />
              You may contact Maistro UK Ltd by email at{' '}
              <Link href="mailto:gdpr-queries@maistro.com">
                gdpr-queries@maistro.com
              </Link>
            </li>
            <li>Cookies</li>
          </ol>
          <p>
            Below is a list of the cookies that we use. We have tried to ensure
            this is complete and up to date, but if you think that we have
            missed a cookie or there is any discrepancy, please let us know.
          </p>
          <p>Strictly necessary</p>
          <p>We use the following strictly necessary cookies:</p>
          <p> </p>
          <table>
            <tbody>
              <tr>
                <td width="25%">
                  <h5>Description of Cookie</h5>
                </td>
                <td width="25%">
                  <h5>Purpose</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Essential cookies</p>
                </td>
                <td>
                  <p>
                    some cookies allow us to identify customers and ensure they
                    can access the account only pages. If a customer opts to
                    disable these cookies, the user will not be able to access
                    all of the content in their account.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p> </p>
        </div>
      </article>
    </div>
  </Layout>
);

export default PrivacyAndCookiePolicy;
